import React from 'react';
import { graphql, navigate, Link } from 'gatsby';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import { Inner } from 'styles/structure';
import SubHero from 'components/SubHero';
import ResourcesGrid from 'components/_grid/Resources';
import ResourceCard from 'components/_card/Resource';
import Pagination from 'components/Pagination';
import NavSelect from 'components/NavSelect';
import client from '../prismic-configuration';
import Prismic from '@prismicio/client'
import smoothScroll from '../utils/smoothScroll';
import titleCase from '../utils/titleCase';
import { withPreview } from 'gatsby-source-prismic';

const Resources = styled.div`
  padding: 4rem 0;
  background-color: ${colors.grey100};
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 7.2rem 0 10.4rem;
  }
`;

const ResourcesNav = styled.div`
  position: sticky;
  top: 6.4rem;
  z-index: 100;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    top: 10.4rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  height: 6.4rem;
  background-color: ${colors.teal600};
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    justify-content: space-between;
  }
`;

const NavInner = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 136rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

const TopicsList = styled.ul`
  display: none;
  padding: 0 4rem;
  flex: 1 1 100%;
  
  li {
    display: inline-block;
    
    & + * {
      margin-left: 3.2vw;  
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    display: block;
    
    li {
      & + * {
        margin-left: 4.8rem;
      }
    }
  }
`;

const TopicsLink = styled(Link)`
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.08s ease-in-out;
  
  &.is-selected,
  &:hover {
    color: ${colors.yellow500};
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
  left: 50%;
  transform: translateX(-50%);
    
  div {
    position: absolute;  
    top: 3.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: ${colors.grey500};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
    &:nth-of-type(1) {
      left: 0.8rem;
      animation: ellipsis1 0.6s infinite;  
    }
    
    &:nth-of-type(2) {
      left: 0.8rem;
      animation: ellipsis2 0.6s infinite;  
    }
    
    &:nth-of-type(3) {
      left: 3.2rem;
      animation: ellipsis2 0.6s infinite;  
    }
    
    &:nth-child(4) {
      left: 5.6rem;
      animation: ellipsis3 0.6s infinite;  
    }
  }
  
  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2.4rem, 0);
    }
  }
  
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

const NoResults = styled.div`
  display: block;
  padding: 4rem 0;
  text-align: center;
  color: ${colors.grey800};
  font-size: 2.4rem;
`;

const LIMIT = 12;

class ResourcePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      page: 1,
      data: this.props.data.allPrismicResource,
      resources: [],
      filteredResources: [],
      currentResources: [],
      type: null,
      loadedResourceTotal: 0,
      firstResource: 0,
      lastResource: LIMIT,
    }
  }

  async fetchResources(after) {
    const response = await client.query(
      Prismic.Predicates.at(
        'document.type',
        'resource'),
        {
          pageSize : 100,
          after: after,
          orderings : '[my.resource.publish_date desc]'
        }
    )

    if (response) {
      const loadedResources = response.results;

      this.setState({
        loadedResourceTotal: this.state.loadedResourceTotal + loadedResources.length,
        resources: this.state.resources.concat(loadedResources.filter(resource => !resource.data.unlisted)),
      });

      if (this.state.loadedResourceTotal < response.total_results_size) {
        this.fetchResources(loadedResources[loadedResources.length - 1].id);
      } else {
        this.setState({
          loaded: true,
          filteredResources: this.state.resources,
          currentResources: this.state.resources.slice(this.state.firstResource, this.state.lastResource),
        });
      }
    }

    return true;
  }

  async filterResourcesByType(type) {
    if (type === 'all') {
      await this.setState({
        filteredResources: this.state.resources,
        type: null,
      });

    } else {
      await this.setState({
        filteredResources: this.state.resources.filter(resource => resource.data.resource_type.uid === type),
        type: type,
      });
    }

    await this.setCurrentResources();
  }

  async setCurrentResources() {
    await this.setState({
      loaded: false,
    })

    await this.setState({
      currentResources: this.state.filteredResources.slice(this.state.firstResource, this.state.lastResource),
      loaded: true,
    })
  }

  async paginate(page) {
    const last = page * LIMIT;
    const self = this;

    smoothScroll('#resources', 600);

    await self.setState({
      page: page,
      firstResource: last - LIMIT,
      lastResource: last,
    });

    await this.setCurrentResources();
  }

  handleSelectChange = (e) => {
    if (e.target.dataset.type === 'types') {
      this.filterResourcesByType(e.target.value);
    } else if (e.target.dataset.type === 'topics') {
      if (e.target.value === 'all') {
        navigate(`/resources`);
      } else {
        navigate(`/topic/${e.target.value}`);
      }
    }
  }

  componentDidMount() {
    this.fetchResources();
  }

  render() {
    const landing = this.props.data.allPrismicResources.edges[0].node.data;
    const self = this;

    return (
      <Layout>
        <Seo
          title={landing.social_title || 'Resources Library'}
          description={landing.social_description}
          image={landing.social_image}
        />

        <SubHero
          heading={landing.hero_heading}
          content={landing.hero_content}
        />

        <ResourcesNav>
          <NavInner>
            <NavLinks>
              <NavSelect
                name='Topics'
                handleSelectChange={self.handleSelectChange}
                options={landing.navigation_topics}
                variant='topics'
              />

              <TopicsList>
                <li>
                  <TopicsLink to='/resources' className='is-selected'>All Topics</TopicsLink>
                </li>

                {landing.navigation_topics.map((topic, i) => {
                  if (topic.topic) {
                    return (
                      <li key={i}>
                        <TopicsLink to={`/topic/${topic.topic.document.uid}`}>{topic.topic.document.data.title}</TopicsLink>
                      </li>
                    )
                  }

                  return null;
                })}
              </TopicsList>

              {self.state.loaded && (
                <NavSelect
                  name='Types'
                  handleSelectChange={self.handleSelectChange}
                  options={landing.navigation_types}
                  value={self.state.type}
                  variant='types'
                />
              )}
            </NavLinks>
          </NavInner>
        </ResourcesNav>

        <Resources id='resources'>
          <Inner>
            {self.state.currentResources.length > 0 && (
              <>
                <ResourcesGrid>
                  {self.state.currentResources.map((resource, i) => {
                    let readableTopic;
                    let readableType;

                    if (resource.data.topic) {
                      const parentTopic = landing.navigation_topics.find(topic => topic.topic.document.uid === resource.data.topic.uid);
                      readableTopic = parentTopic.topic.document.data.title;
                    }

                    if (resource.data.resource_type) {
                      readableType = titleCase(resource.data.resource_type.uid.replaceAll('-', ' '));
                    }

                    return (
                      <li key={`resource-card-${i}`}>
                        <ResourceCard
                          uid={resource.uid}
                          image={resource.data.preview_image || resource.data.feature_image}
                          title={resource.data.title[0].text}
                          type={readableType}
                          topic={readableTopic}
                          preview_text={resource.data.preview_text}
                        />
                      </li>
                    )
                  })}
                </ResourcesGrid>

                <Pagination
                  resourcesPerPage={LIMIT}
                  totalResources={self.state.filteredResources.length}
                  paginate={(page) => self.paginate(page)}
                  currentPage={self.state.page}
                />
              </>
            )}

            {self.state.currentResources.length === 0 && self.state.loaded && (
              <NoResults>No resources were found for that type.</NoResults>
            )}

            {!self.state.loaded && (
              <Loader>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </Loader>
            )}
          </Inner>
        </Resources>
      </Layout>
    );
  }
}

export const query = graphql`
  query ResourcesLanding {
    allPrismicResources {
      edges {
        node {
          data {
            hero_heading {
              raw
            }
            hero_content {
              raw
            }
            navigation_topics {
              topic {
                document {
                  ... on PrismicTopic {
                    uid
                    data {
                      title
                      description {
                        raw
                      }
                    }
                  }    
                }
              }
            }
            navigation_types {
              type {
                document {
                  ... on PrismicType {
                    id
                    uid
                    data {
                      title
                    }
                  }    
                }
              }
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
    allPrismicResource {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          uid
          data {
            unlisted
            embed {
              url
            }
            feature_image {
              url
              alt
            }
            preview_image {
              url
              alt
            }
            preview_text
            title {
              text
            }
            topic {
              document {
                ... on PrismicTopic {
                  uid
                  data {
                    title
                    description {
                      raw
                    }
                  }
                }    
              }
            }
            resource_type {
              document {
                ... on PrismicType {
                  uid
                  data {
                    title
                  }
                }    
              }
            }
          }
        }
      }
      totalCount
      }
  }
`;

export default withPreview(ResourcePage);